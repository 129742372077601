button.menu-button-mobile.m-l-1.btn.btn-primary,
button.menu-button-mobile.m-l-1.btn.btn-primary:hover {
  background-color: transparent !important;
  color: black !important;
  border: none;
  font-size: 1.5rem;
}

.primary-button {
  padding: 0.5rem 2rem !important;
  background-color: transparent !important;
  color: inherit !important;
  float: right;
  margin-top: 2rem !important;
}

.b-0 {
  bottom: 0 !important;
}

.r-0 {
  right: 0 !important;
}

.z-i-_5 {
  z-index: -1 !important;
}

.a-s-f-e {
  align-self: flex-end !important;
}

.offcanvas-container {
  /* Set initial position outside the viewport on the right */
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.offcanvas-container.offcanvas-show {
  /* Set final position inside the viewport on the left */
  transform: translateX(0);
}

.r_0 {
  right: 0 !important;
}

.css-106sn7g-MuiPaper-root,
.css-1kaw386 {
  box-shadow: none !important;
}

.red_clr {
  color: red !important;
}

.drop_hover:hover {
  background-color: var(--bg_br_clr) !important;
  color: black !important;
}

.b-r-t-5 {
  border-radius: 5px 5px 0px 0px !important;
}

.b-r-b-5 {
  border-radius: 0px 0px 5px 5px !important;
}

@media screen and (min-width: 900px) {
  .m-q-d-f {
    display: flex !important;
  }

  .m-q-m-r-2 {
    margin-right: 2rem !important;
  }
}

.input::placeholder {
  font-size: 1rem !important;
}

.t_2 {
  top: 2rem !important;
}

.t_7 {
  top: 2em !important;
}

.background_image:before {
  // background-image: url("../Image/bg_image.png") !important;
  background: url("../Image/bg_image.png") no-repeat 50% / cover;
  background-attachment: fixed;
  content: "";
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.css-187mznn-MuiSlider-root {
  color: #b7b7b7 !important;
}

.modal-content {
  border-radius: 15px !important;
}

.max-w-40rem {
  max-width: 35rem !important;
}

input:focus {
  outline: none !important;
}

input::placeholder {
  font-size: 1.5rem !important;
  color: rgb(211, 211, 211) !important;
}

.modal-content {
  border: none !important;
}

.pa-5px {
  padding: 5px 10px !important;
}

.max-w-45 {
  max-width: 45rem !important;
}

.min-w-35 {
  min-width: 35rem !important;
}

.w-s-n-w {
  white-space: nowrap !important;
}

.search {
  border-color: var(--o_clr) !important;
}

tr,
td,
table,
tbody,
thead,
th {
  background-color: transparent !important;
  color: white !important;
}

.f_green {
  fill: rgb(0, 201, 0) !important;
}

.place::placeholder {
  font-size: 0.8rem !important;
}

.c_green {
  color: rgb(0, 201, 0) !important;
}

.j-c-c {
  justify-content: center !important;
}

@media screen and (max-width: 600px) {
  input {
    width: 10rem !important;
  }

  .min-w-35 {
    min-width: 20rem !important;
  }
}

.dropdown-toggle::after {
  display: none !important;
}

tr,
td,
table,
tbody,
thead,
th {
  color: black !important;
}

.ant-picker-footer {
  padding: 1rem !important;
}

:where(.css-dev-only-do-not-override-acm2ia).ant-picker .ant-picker-input>input:placeholder-shown {
  border: none !important;
}

.datepicker {
  border: 1px solid var(--gray_clr) !important;
}

.datepicker {
  outline: none !important;
}

@media screen and (min-width:900px) {
  .m-q-d-f {
    display: flex !important;
  }
}

@media screen and (max-width:900px) {
  .m-q-w-100 {
    width: 100% !important;
  }
}


.grid {
  --template-column-gutters: 8px;
  --template-columns: 1;
  --template-column-compact-multiplier: 1;
  --template-reduced-columns: 0;
  --template-reduced-columns-multiplier: 1;
  display: grid;
  gap: 30px;
  grid-auto-rows: minmax(0px, 1fr);
  grid-template-columns: repeat(calc(var(--template-columns) - (var(--template-reduced-columns) * var(--template-reduced-columns-multiplier))), minmax(0, 1fr));
}

@media screen and (min-width:425px) {
  .grid {
    --template-columns: 1;
  }
}

@media screen and (min-width:768px) {
  .grid {
    --template-columns: 2;
  }
}

@media screen and (min-width:1024px) {
  .grid {
    --template-columns: 2;
  }
}

@media screen and (min-width:1440px) {
  .grid {
    --template-columns: 3;
  }
}

@media screen and (min-width:1920px) {
  .grid {
    --template-columns: 4;
  }
}

@media screen and (min-width:2560px) {
  .grid {
    --template-columns: 5;
  }
}