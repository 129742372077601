.modal.show .modal-dialog {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}
:where(.css-dev-only-do-not-override-acm2ia).ant-picker-dropdown,.ant-picker-dropdown , :where(.css-acm2ia).ant-picker-dropdown{
    z-index: 1064 !important;
}

:where(.css-acm2ia).ant-picker .ant-picker-input >input{
    border: none !important;
    outline: none !important;
}

