:root {
    --brown-color: #33312d;
    --color: white;
    --dark_brown_clr: #000000;
    --o_clr: rgb(254, 205, 133);
    --br_clr: rgba(248, 248, 248, 0.05);
    --dark_br_clr: rgb(204, 204, 204);
    --gray_clr: #C2BAB5;
    --placeholder_clr: #575A68;
    --box_clr: #006b57;
    --bg_btn_clr: #3f3b3b85;
    --btn_clr: #E0CCAD;
    --max_clr: #722F0D;

}

html,
body,
input,
select,
#dropdown,
.modal-content,
.pa-1.offcanvas.offcanvas-start.show,
svg,
.dropdown-menu.show {
    // background-color: var(--dark_brown_clr) !important;
    color: var(--color) !important;
    fill: var(--color) !important;
    border-color: var(--color) !important;
}

.dropdown-menu.show {
    background-color: var(--o_clr) !important;
    color: var(--max_clr);
}

input {
    border: 1px solid var(--gray_clr) !important;
}

input:focus {
    outline: none !important;
}

.bg_btn_clr {
    background-color: var(--bg_btn_clr) !important;
}

.max_clr {
    color: var(--max_clr) !important;
}

.f_max_clr {
    fill: var(--max_clr) !important;
}


.btn_clr {
    color: var(--btn_clr) !important;
}

.f_white {
    fill: white !important;
}

.f_gray_clr {
    fill: var(--gray_clr) !important;
}

.bg_box_clr {
    background-color: var(--box_clr) !important;
}

.placeholder_clr {
    color: var(--placeholder_clr);
}

.bg_o_clr {
    background-color: var(--o_clr) !important;
}

.o_clr {
    color: var(--o_clr) !important;
}

.f_o_clr {
    fill: var(--o_clr) !important;
}

.bg_br_clr {
    background-color: var(--br_clr) !important;
    box-shadow: inset 0px 0px 12px 6px #ffffff3d !important;
}

.txt_clr {
    color: var(--dark_br_clr) !important;
}

.gray_clr {
    color: var(--gray_clr) !important;
}

.bg_gray_clr {
    background-color: var(--gray_clr) !important;
}

svg {
    background-color: transparent !important;
}

.bg_brown_clr {
    background-color: var(--brown-color) !important;
}

.f-b {
    fill: black !important;
}

a.all_lock.active {
    color: var(--o_clr) !important;
}

.bg_btn_clr_drop {
    background-color: black !important;
}